/* eslint-disable max-len */
import { PageRendererProps } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import styled from 'styled-components'

import consultImage from '../../assets/static/consult/consult3.jpg'
import orthodontics2Image from '../../assets/static/service/orthodontics2.jpg'
import orthoTypeTableDesktop from '../../assets/static/service/orthoType_table_desktop.png'
import ServiceLayout from '../../components/Layout/ServiceLayout'
import Seo from '../../components/Seo'
import { ContentTitle } from '../../components/Titles/ContentTitle'
import { space } from '../../constants/length'
import Collapse from './components/Collapse'
import ContentSection from './components/ContentSection'
import ListItem from './components/ListItem'

const StyledContentTitle = styled(ContentTitle)`
  margin-bottom: ${space.l}px;
`
const QuestionSection = styled.div`
  margin-top: ${space.l * 2}px;
`

const ListGroup = styled.div`
  margin-top: ${space.m}px;
`

/** 傳統矯正 */
const Orthodontics = (props: PageRendererProps) => {
  const { formatMessage } = useIntl()

  const routes = [
    {
      label: formatMessage({ id: 'breadcrumb.index' }),
      path: '/'
    },
    {
      label: formatMessage({ id: 'service.index' }),
      path: '/service/digital'
    },
    {
      label: formatMessage({ id: 'service.orthodontics' }),
      path: '/service/orthodontics'
    }
  ]

  return (
    <ServiceLayout routes={routes} consultImage={consultImage} pageBannerInfo={formatMessage({ id: 'banner.orthodontics' })} {...props}>
      <Seo
        title='傳統矯正推薦：戴蒙自鎖式矯正-唯一指定日不落矯正專科團隊'
        metaDescription='不只科技在進步，矯正方式也在進化中！您知道最新自鎖式矯正、戴蒙矯正是如何加速牙齒矯正嗎？現代牙齒矯正五花八門，就讓遍布台北、新竹、台中、台南的日不落矯正專科集團為您評估專屬牙齒矯正計畫。'
      />
      <StyledContentTitle>{formatMessage({ id: 'service.orthodontics' })}</StyledContentTitle>
      <ContentSection id='lock' imageUrl={orthodontics2Image} title='自鎖式/戴蒙矯正(傳統矯正)' imageAlt='自鎖式/戴蒙矯正(傳統矯正)'>
        <p>
          美國最新自鎖式矯正器是以內建滑動開關來將矯正線固定住，矯正器不再需要鐵絲或橡皮筋就可以被安全固定住並且移動牙齒，不但使牙齒的受力變小，疼痛感大幅降低且牙齒移動的速度也將變快；沒有了橡皮筋使得清潔更加容易，生活品質不因矯正而影響，大大提高了矯正滿意度！
        </p>
      </ContentSection>
      <ContentSection imageUrl={orthoTypeTableDesktop} title='矯正方式比較' imagePosition='fluid' imageAlt='「隱形矯正」與「傳統矯正」方式比較' borderInvisible />
      <ContentSection title='誰適合傳統矯正'>
        <ListGroup>
          <ListItem content='口腔狀況較複雜，經由醫師評估後為中重症的患者' />
          <ListItem content='自律性較低，容易忘東忘西' />
          <ListItem content='預算有限，通常傳統矯正的價格會低於隱形矯正，不過還是會依案例複雜度而改變。' />
        </ListGroup>
      </ContentSection>
      <QuestionSection>
        <ContentSection title='常見問題'>
          <Collapse title='傳統矯正會比隱形矯正快嗎？'>
            <p>
              若是與自律性佳的隱形矯正患者相比，
              在輕～中度牙齒凌亂的患者中，傳統矯正不一定會比隱形矯正快，因隱形矯正是已經將牙齒移動幅度利用數位模擬系統設計在牙套中，讓患者在無需回診的情況下也能推進矯正進度；而傳統矯正主要依賴回診時由醫師調整矯正金屬線來讓牙齒移動，因此在輕～中度牙齒凌亂的條件相同下，兩邊的速度並不會相差太多。{' '}
            </p>
          </Collapse>
          <Collapse title='傳統矯正多久需要回診？'>
            <p>平均3~4週需回診，大約等於一個月一次。回診時除了監督矯正進度外，還需要調整矯正金屬線，因此相較於隱形矯正，在回診時要記得保留較長的時間空檔唷！ </p>
          </Collapse>
          <Collapse title='進行傳統矯正需忌口嗎？'>
            <p>
              傳統矯正要小心不要咬太硬、太黏的東西，例如：牛軋糖、麥芽糖之類的。另外，比較有韌性的像是牛排之類的也會建議要切小塊後放到後牙去慢慢咀嚼。延伸閱讀更多「
              <a
                href='https://sunlight.dentist/news/650abef85efce201607fa54b'
                title='矯正牙齒為什麼要咬合墊高？飲食好痛苦，前輩教你怎麼吃！傳統矯正器跟隱形牙套墊高差異性【日不落牙醫集團】'
              >
                <strong>矯正飲食注意事項</strong>
              </a>
              」！
            </p>
          </Collapse>
        </ContentSection>
      </QuestionSection>
    </ServiceLayout>
  )
}

export default Orthodontics
